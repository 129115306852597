import React from "react";
import {connect} from "react-redux";
import {withRouter} from "../withRouter";
import CompanyMap from "../../components/company/company-map";
import {Button} from "primereact/button";
import {faBuildingFlag, faSpaceShuttle, faStore, faBuildingColumns} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Dialog} from "primereact/dialog";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Badge} from "primereact/badge";
import MarketDialog from "../../components/market/buy-dialog";
import LaunchRocket from "../../components/launch-rocket";
import {CompanyApi} from "../../api/company-api";
import {Toast} from "primereact/toast";

type State = {
    showRocketsDialog: boolean,
    showLaunchpadsDialog: boolean,
    showMarketDialog: boolean,
    launchRocket: boolean,
    rocketIdToLaunch: string | null,
    canObtainGouvernementalGrant: boolean,
    toast: React.RefObject<any>
}

class DetailsMobile extends React.Component<any, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            showLaunchpadsDialog: false,
            showRocketsDialog: false,
            showMarketDialog: false,
            launchRocket: false,
            rocketIdToLaunch: null,
            canObtainGouvernementalGrant: this.canObtainGouvernementalGrant(this.props.company),
            toast: React.createRef()
        }
    }

    canObtainGouvernementalGrant = (company: any) => {
        return company
            && company.lastGouvernementalGrant
            && new Date().getTime() - new Date(company.lastGouvernementalGrant).getTime() > 1000 * 60 * 60 * 2
    }

    obtainGouvernementalGrant = () => {
        if(this.state.canObtainGouvernementalGrant){
            CompanyApi.obtainGouvernementalGrant(this.props.company._id).then((response) => {
                this.props.setCompany(response.data);

                this.setState({canObtainGouvernementalGrant: false})
                this.showToast("success", "Grant obtained!", "You obtained help from gouvernment! Next help available in 2h.")
            });
        }
    }

    showToast = (severity: 'success' | 'info' | 'danger' | 'warn' | 'secondary' | 'contrast', title: string, message: string) => {
        this.state.toast.current?.show({severity, summary: title, detail: message})
    }

    render() {
        return (
            <div style={{position: 'relative'}}>
                <Toast ref={this.state.toast} />

                <CompanyMap company={this.props.company} height="85dvh" width="85vw"/>

                <Button className="p-button-rounded p-button-success pa-5"
                        style={{zIndex: 500, position: 'absolute', top: '20px', right: '10px'}}
                        onClick={() => this.setState({showMarketDialog: true})}>
                    <FontAwesomeIcon icon={faStore} fontSize="1rem"/>
                </Button>

                <Button className="p-button-rounded p-button-info pa-5"
                        style={{zIndex: 500, position: 'absolute', top: '80px', right: '10px'}}
                        onClick={() => this.setState({showLaunchpadsDialog: true})}>
                    <FontAwesomeIcon icon={faBuildingFlag} fontSize="1rem"/>
                </Button>

                <Button className="p-button-rounded p-button-info pa-5"
                        style={{zIndex: 500, position: 'absolute', top: '140px', right: '10px'}}
                        onClick={() => this.setState({showRocketsDialog: true})}>
                    <FontAwesomeIcon icon={faSpaceShuttle}/>
                </Button>

                <Button className="p-button-rounded p-button-info pa-5"
                        style={{zIndex: 500, position: 'absolute', top: '200px', right: '10px'}}
                        disabled={!this.state.canObtainGouvernementalGrant}
                        onClick={() => this.obtainGouvernementalGrant()}>
                    <FontAwesomeIcon icon={faBuildingColumns}/>
                </Button>

                {this.state.showLaunchpadsDialog && (
                    <Dialog header="Launchpads" visible={this.state.showLaunchpadsDialog}
                            style={{width: '80vw'}}
                            onHide={() => this.setState({showLaunchpadsDialog: false})}>
                        <DataTable value={this.props.company.launchPads}>
                            <Column field="name" header="Name"></Column>
                            <Column field="crew" header="Crew" body={(item: any) => {
                                return item.crew
                                    ? <Badge value="Yes" severity="success"></Badge>
                                    : <Badge value="No" severity="danger"></Badge>
                            }}></Column>
                            <Column field="isUsed" header="Used" body={(item: any) => {
                                return item.isUsed
                                    ? <Badge value="Yes" severity="danger"></Badge>
                                    : <Badge value="No" severity="success"></Badge>
                            }}></Column>
                        </DataTable>
                    </Dialog>
                )}

                {this.state.showRocketsDialog && (
                    <Dialog header="Rockets" visible={this.state.showRocketsDialog}
                            style={{width: '80vw'}}
                            onHide={() => this.setState({showRocketsDialog: false})}>

                        {this.state.launchRocket && (
                            <LaunchRocket rocketId={this.state.rocketIdToLaunch!!}/>
                        )}

                        <DataTable value={this.props.company.rockets}
                                   tableStyle={{minWidth: '50rem'}}>
                            <Column field="name" header="Name"></Column>
                            <Column field="orbital" header="Orbital" body={(item: any) => {
                                return item.orbital
                                    ? <Badge value="Yes" severity="success"></Badge>
                                    : <Badge value="No" severity="danger"></Badge>
                            }}/>
                            <Column field="maxPayloadUnits"
                                    header="Max payloads"></Column>
                            <Column field="maxPayloadWeight" header="Payloads weight"
                                    body={(i) => <p>{i.maxPayloadWeight}t</p>}></Column>
                            <Column body={(i) => <Button severity="warning" onClick={() => this.setState({
                                launchRocket: true,
                                rocketIdToLaunch: i._id
                            })}>LAUNCH</Button>}/>
                        </DataTable>
                    </Dialog>
                )}

                {this.state.showMarketDialog && (
                    <Dialog header="Market" visible={this.state.showMarketDialog} style={{width: '90vw'}}
                            onHide={() => this.setState({showMarketDialog: false})}>
                        <MarketDialog company={this.props.company}/>
                    </Dialog>
                )}
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    setCompany: (company: any) => (
        dispatch({type: "SET_COMPANY", payload: company})
    )
})

const mapStateToProps = (state: any) => {
    const {company} = state.company
    return {company: company}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DetailsMobile));