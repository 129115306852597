import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './views/main/home';
import Signup from './views/security/signup';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navigation from './views/main/navigation';
import Logout from './views/security/logout';
import Login from './views/security/login';
import CompanyDetails from './views/company/details';

// import "primereact/resources/themes/viva-dark/theme.css"; //theme
import "primereact/resources/primereact.min.css";                   //core css
import "primeicons/primeicons.css";                                 //icons

import "leaflet/dist/leaflet.css"; // Leaflet stylesheet

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as primereact from "primereact";
import {Provider} from "react-redux";
import store from "./store";

const isMobile = () => window.innerWidth <= 768;

const isDarkMode = () =>
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

if(isDarkMode()) {
    // @ts-ignore
    import("primereact/resources/themes/viva-dark/theme.css");
} else {
    // @ts-ignore
    import("primereact/resources/themes/viva-light/theme.css");
}



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <div className="nav">
          <Navigation />
        </div>
        <div className={isMobile() ? 'pa-5' : 'pa-10'}>
          <Routes>
            <Route path="/home" element={<App />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/login' element={<Login />} />
            <Route path='/logout' element={<Logout />} />
            <Route path='/company/details/:id' element={<CompanyDetails />} />
          </Routes>
        </div>
      </BrowserRouter>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
