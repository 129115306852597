import Axios from "../api/config/axios.config";

export class UserApi {

  public static getUserInfos(): Promise<any> {
    return Axios.get("/user/me");
  }

  public static getUserCompanies(): Promise<any> {
    return Axios.get(`/user/companies`);
  }

  public static deleteUserCompany(companyId: string): Promise<any> {
    return Axios.delete(`/user/companies/${companyId}`);
  }

  public static updateUserCompany(body: any, companyId: string): Promise<any> {
    return Axios.put(`/user/companies/${companyId}`, body)
  }

  public static login(body: any): Promise<any> {
    return Axios.post("/security/login", body)
  }

  public static registerUser(body: any): Promise<any> {
    return Axios.post("/security/signup", body)
  }
}