import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
})

instance.interceptors.request.use(function (reqConfig) {
    reqConfig.headers = {
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
    }
    return reqConfig;
}, function (error) {
    return Promise.reject(error);
});

instance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const originalRequest = error.config;
        if (error.response && error.response.status === 403) {
            if (error.response.data && error.response.data.code) {
                if (error.response.data.code === 'TOKEN_EXPIRED') {
                    return instance.get('/security/refresh-token').then((response) => {
                        const token = response.data.refresh_token;
                        originalRequest._retry = true;
                        localStorage.setItem('token', token);
                        error.config.headers.Authorization = `Bearer ${token}`;
                        instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                        return instance(originalRequest);
                    })
                }
            }
        }else{
            return Promise.reject(error);
        }
    }
)

export default instance;