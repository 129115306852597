import {Button, Card} from "primereact";
import React from "react";
import DeleteCompany from "./delete-dialog";
import EditCompany from "./edit-dialog";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBuildingFlag, faRocket, faUsers} from '@fortawesome/free-solid-svg-icons'
import {withRouter} from "../../views/withRouter";
import {connect} from "react-redux";

type State = { showEditDialog: boolean, showDeleteDialog: boolean }

class CompanyCard extends React.Component<any, State> {

    constructor(props: any) {
        super(props);
        this.state = {showEditDialog: false, showDeleteDialog: false};
    }

    goDetails = () => {
        this.props.setCompany(this.props.company);
        this.props.history.push('/company/details/' + this.props.company._id);
    }

    isMobile = () => {
        return window.innerWidth <= 768;
    }

    render() {
        return (
            <Card style={{border: "1px solid #353535"}} title={() => (
                <div className="d-flex justify-between align-center">
                    <span className="d-flex flex-column">
                        <span>{this.props.company.name}</span>
                        <span style={{fontSize: '1rem'}}>
                            {this.props.company.stockMarketValue.toLocaleString()}$
                            <span className="ml-5">
                                {this.props.company.oldStockMarketValue === this.props.company.stockMarketValue
                                    ? <i className="pi pi-equals" style={{color: 'orange'}}/>
                                    : (this.props.company.oldStockMarketValue < this.props.company.stockMarketValue
                                            ? <i className="pi pi-sort-up-fill" style={{color: 'green'}}/>
                                            : <i className="pi pi-sort-down-fill" style={{color: 'red'}}/>
                                    )
                                }
                            </span>
                        </span>
                    </span>
                    <span>
                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-info mr-10"
                                aria-label="Bookmark" onClick={() => this.setState({
                            showEditDialog: !this.state.showEditDialog,
                            showDeleteDialog: false
                                })}/>
                        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger"
                                aria-label="Bookmark"
                                onClick={() => this.setState({
                                    showDeleteDialog: !this.state.showDeleteDialog,
                                    showEditDialog: false
                                })}/>
                    </span>
                </div>
            )}>
                {this.isMobile()
                    ? (
                        <div>
                            <div>
                                <p>{this.props.company.description}</p>
                                <Button onClick={() => this.goDetails()} outlined>Go to the company</Button>
                            </div>
                            <div>
                                <h3>Stats</h3>
                                <div className="d-flex align-start gap-20">
                                    <span className="mb-10 d-flex align-center gap-5"><span
                                        style={{fontSize: "1rem"}}>{this.props.company.launchPads.length}</span><FontAwesomeIcon
                                        icon={faBuildingFlag} size="lg"/></span>
                                    <span className="mb-10 d-flex align-center gap-5"><span
                                        style={{fontSize: "1rem"}}>{this.props.company.rockets.length}</span><FontAwesomeIcon
                                        icon={faRocket} size="lg"/></span>
                                    <span className="mb-10 d-flex align-center gap-5"><span
                                        style={{fontSize: "1rem"}}>{this.props.company.employees}</span><FontAwesomeIcon
                                        icon={faUsers} size="lg"/></span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="d-flex justify-between">
                            <div className="first-column flex-2">
                                <p>{this.props.company.description}</p>
                                <Button onClick={() => this.goDetails()} outlined>Go to the company</Button>
                            </div>
                            <div className="second-column flex-1">
                                <h3>Stats</h3>
                                <div className="d-flex align-start flex-wrap gap-30">
                                <span className="mb-10 d-flex align-center gap-10"><span style={{fontSize: "2rem"}}>{this.props.company.launchPads.length}</span><FontAwesomeIcon icon={faBuildingFlag} size="xl"/></span>
                                    <span className="mb-10 d-flex align-center gap-10"><span style={{fontSize: "2rem"}}>{this.props.company.rockets.length}</span><FontAwesomeIcon icon={faRocket} size="xl"/></span>
                                    <span className="mb-10 d-flex align-center gap-10"><span style={{fontSize: "2rem"}}>{this.props.company.employees}</span><FontAwesomeIcon icon={faUsers} size="xl"/></span>
                                </div>
                            </div>
                        </div>
                    )
                }

                {this.state.showEditDialog && (
                    <EditCompany company={this.props.company}/>
                )}

                {this.state.showDeleteDialog && (
                    <DeleteCompany company={this.props.company}/>
                )}
            </Card>
        );
    }
}


const mapDispatchToProps = (dispatch: any) => ({
    setCompany: (company: any) => (
        dispatch({type: "SET_COMPANY", payload: company})
    )
})

export default connect(null, mapDispatchToProps)(withRouter(CompanyCard));
