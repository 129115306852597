import { Button, InputText, InputTextarea, Messages } from "primereact";
import React from "react";
import { UserApi } from "../../api/user-api";

type State = { name: string, description: string }
class EditCompany extends React.Component<any, State> {

  msg: any;

  constructor(props: any) {
    super(props);
    this.state = {
      name: this.props.company.name,
      description: this.props.company.description
    };
    this.msg = React.createRef();
  }

  handleNameChange = (event: any) => {
    this.setState({ name: event.target.value });
  }

  handleDescriptionChange = (event: any) => {
    this.setState({ description: event.target.value });
  }

  handleSubmit = (e: any) => {
    e.preventDefault();
    UserApi.updateUserCompany({
      name: this.state.name,
      description: this.state.description
    }, this.props.company._id).then((response: any) => {
      window.location.href = '/home';
      return response;
    }).catch((err: any) => {
      (this.msg as any).show([
        { severity: 'error', detail: err.response.data.message }
      ]);
    });
  }

  render() {
    return (
      <div className="pa-10">
        <Messages ref={(el: any) => this.msg = el} />
        <form onSubmit={this.handleSubmit}>
          <div className="p-float-label">
            <InputText id="name" type="text" value={this.state.name} onChange={this.handleNameChange} required style={{ width: "100%" }} />
            <label htmlFor="name">Name</label>
          </div>
          <div className="p-float-label mt-10">
            <InputTextarea id="description" value={this.state.description} onChange={this.handleDescriptionChange} required style={{ width: "100%" }} />
            <label htmlFor="description">Description</label>
          </div>
          <Button label="Update" icon="pi pi-check" iconPos="right" className="mt-20" />
        </form>
      </div>
    );
  }
}

export default EditCompany;