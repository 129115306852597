import React from "react";
import {publish} from "../utils/events";
import {RocketApi} from "../api/rocket-api";
import {Message} from "primereact/message";
import {Button} from "primereact/button";
import {Card} from "primereact/card";
import {Dialog} from "primereact/dialog";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Badge} from "primereact/badge";

type Props = { rocketId: string }
type State = {
    rocketLaunched: boolean,
    counter: number;
    launchResult: {
        success: boolean;
        rocketName: string;
        payloads?: { name: string; quantity: number }[];
        rewards?: { type: 'MONEY' | 'STOCK_VALUE'; value: number }[];
    } | null;
}

class RocketLaunch extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {rocketLaunched: false, counter: 5, launchResult: null};
    }

    componentDidMount() {
        const interval = setInterval(() => {
            if (this.state.counter === 0) {
                RocketApi.launchRocket(this.props.rocketId).then((result) => {
                    clearInterval(interval);
                    this.setState({launchResult: result.data});
                })
            } else {
                this.setState({counter: this.state.counter - 1});
            }
        }, 1000);
    }

    close = () => {
        publish('close-dialog', {type: 'close-launch-rocket'})
    }


    render() {
        return (
            <Dialog closable={!!this.state.launchResult} header="Rocket launch" visible={true} onHide={() => this.close()}>
                {this.state.launchResult
                    ? (
                        <Card>
                            <Message severity={this.state.launchResult.success ? "success" : "error"}
                                     text={this.state.launchResult.success ? "Rocket launched successfully" : "Launch failed"}
                                     style={{width: '100%'}}/>

                            <div>
                                <h3>Rocket: {this.state.launchResult.rocketName}</h3>
                            </div>

                            <div>
                                <h3>Payloads:</h3>
                                <DataTable value={this.state.launchResult.payloads} tableStyle={{ minWidth: '50rem' }}>
                                    <Column field="name" header="Name"></Column>
                                    <Column field="quantity" header="Quantity"></Column>
                                </DataTable>
                            </div>

                            <div>
                                <h3>Rewards :</h3>

                                <DataTable value={this.state.launchResult.rewards} tableStyle={{ minWidth: '50rem' }}>
                                    <Column field="type" header="Type" body={(i) => i.type === 'MARKET' ? 'Market' : (i.type === 'STOCK_VALUE' ? 'Stock value' : 'Contract')}></Column>
                                    <Column field="value" header="Value" body={(i) => (
                                        <Badge severity={i.value > 0 ? 'success' : 'danger'} value={i.value.toLocaleString() + " $"}/>
                                    )}></Column>
                                </DataTable>
                            </div>
                        </Card>
                    ) : (
                        <div className="rocket">
                            <div className="rocket-body">
                                <div className="rocket-window d-flex justify-center">
                                    <h1>{this.state.counter}</h1>
                                </div>
                            </div>
                            <div className="rocket-fire"></div>
                        </div>
                    )
                }
            </Dialog>
        )
    }
}

export default RocketLaunch;
