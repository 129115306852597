import Axios from "../api/config/axios.config";

export class MarketApi {

    public static getItemsTypes(): Promise<any> {
        return Axios.get("/market/items/types");
    }

    public static getItemsByType(type: string) {
        return Axios.get(`/market/items/${type}`);
    }

    public static getAllItems(): Promise<any> {
        return Axios.get("/market/items");
    }

    public static buyItem(body: any): Promise<any> {
        return Axios.post("/market/buy", body)
    }
}