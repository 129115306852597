import React from "react";
import {connect} from "react-redux";
import {Card} from "primereact/card";
import {ProgressBar} from "primereact/progressbar";
import {Button} from "primereact";
import {MarketApi} from "../../api/market-api";
import {PrimeIcons} from "primereact/api";

type Props = { company: any }
type State = { extraFeaturesItems: any[] }
class ExtraFeatures extends React.Component<Props, State> {
    
    constructor(props: any){
        super(props);
        this.state = {extraFeaturesItems: []}

        MarketApi.getItemsByType("EXTRA_FEATURE").then((r) => {
            this.setState({extraFeaturesItems: r.data});
        });
    }
    
    render(){
        return (
            <Card style={{border: "1px solid #353535"}}>
                <h2>Extra functionalities</h2>
                <div className="features-list d-flex align-start">
                    {this.state.extraFeaturesItems.map((object: any, i: number) => {

                        const hasExtraFeature = this.props.company.extraFeatures.includes(object.meta.code);

                        if(hasExtraFeature){
                            return (
                                <div className="content d-flex flex-column align-center"
                                     style={{
                                         position: 'relative',
                                         overflow: 'hidden',
                                         border: "1px solid #353535",
                                         borderRadius: '5px',
                                         width: "30dvw",
                                         height: '20dvh',
                                         margin: "0 10px"
                                     }}>
                                    <img src={"../../assets/" + object.img} alt={object.name}
                                         style={{position: 'absolute', filter: 'opacity(20%)', zIndex: '1', top: '0%', width: 'inherit'}}/>
                                    <div style={{zIndex: 2}} className="d-flex flex-column align-center justify-center">
                                        <span className="d-flex align-center justify-start">
                                            <h2>{object.name}</h2>
                                            <i className="pi pi-verified mx-10" style={{fontSize: '2rem'}}/>
                                        </span>
                                        <Button label="Open" className="p-button-success" outlined/>
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className="content d-flex flex-column align-center"
                                     style={{
                                         position: 'relative',
                                         overflow: 'hidden',
                                         border: "1px solid #353535",
                                         borderRadius: '5px',
                                         width: "30dvw",
                                         height: '20dvh',
                                         margin: "0 10px"
                                     }}>
                                    <img src={"../../assets/" + object.img} alt={object.name}
                                         style={{position: 'absolute', filter: 'opacity(20%)', zIndex: '1', top: '0%', width: 'inherit'}}/>
                                    <div style={{zIndex: 2}} className="d-flex flex-column align-center justify-center">
                                        <h2>{object.name}</h2>
                                        <Button label="Buy" className="p-button-info" outlined/>
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
            </Card>
        )
    }

}

const mapStateToProps = (state: any) => {
    const {company} = state.company
    return {companyStoreState: company}
}

export default connect(mapStateToProps, null)(ExtraFeatures);