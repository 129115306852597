import { Button, InputText, Messages } from "primereact";
import React from "react";
import { Link } from "react-router-dom";
import { UserApi } from "../../api/user-api";

type State = { email: string, totp: string }
class Login extends React.Component<any, State> {

  msg: any;

  constructor(props: any) {
    super(props);
    this.state = {
      email: '',
      totp: ''
    }
    this.msg = React.createRef();
  }

  handleSubmit = (event: any) => {
    event.preventDefault();
    UserApi.login({
      email: this.state.email,
      token: this.state.totp
    }).then((response: any) => {
      localStorage.setItem('token', response.data.access_token);
      window.location.href = '/home';
    }).catch((error: any) => {
      this.msg.show({ severity: 'error', summary: 'Error', detail: error.response.data.message });
    })
  }

  handleEmailChange = (event: any) => {
    this.setState({ email: event.target.value });
  }

  handleTotpChange = (event: any) => {
    this.setState({ totp: event.target.value });
  }

  render() {
    return (
      <div>
        <h1>Login</h1>
        <Messages ref={(el: any) => this.msg = el}></Messages>
        <form className="d-flex flex-column align-center justify-center" onSubmit={this.handleSubmit}>
          <span className="p-float-label">
            <InputText value={this.state.email} onChange={this.handleEmailChange} required />
            <label htmlFor="in">Email</label>
          </span>

          <span className="p-float-label mt-10">
            <InputText value={this.state.totp} onChange={this.handleTotpChange} required />
            <label htmlFor="in">Temporary code</label>
          </span>
          
          <p>Doesn't have an account ? <Link to={'/signup'}>Register yourself now !</Link></p>
          <Button label="Submit" type="submit" icon="pi pi-check" iconPos="right" className="mt-10" />
        </form>
      </div>
    );
  }
}

export default Login;