import { Button } from "primereact";
import React from "react";
import { UserApi } from "../../api/user-api";

class DeleteCompany extends React.Component<any, any> {

  confirm = () => {
    UserApi.deleteUserCompany(this.props.company._id)
    .then((response: any) => {
      window.location.href = '/home';
    }).catch((err: any) => {
      alert(err)
    });
  }

  cancel() {
    window.location.href = '/home';
  }

  render() {
    return (
      <div>
        <p>Do you really want to delete the {this.props.company.name} company from your account ?</p>
        <Button className="p-button-outlined mr-10" label="Cancel" icon="pi pi-times" iconPos="left" onClick={this.cancel} />
        <Button className="mt-10" label="Confirm" icon="pi pi-check" iconPos="right" onClick={this.confirm} />
      </div>
    );
  }
}

export default DeleteCompany;
