import React, {RefObject} from "react";
import {UserApi} from "../../api/user-api";
import {withRouter} from "../withRouter";
import {Column} from 'primereact/column';
import {ContractsApi} from "../../api/contracts-api";
import {Card} from "primereact/card";
import {Button, Dialog} from "primereact";
import {RocketApi} from "../../api/rocket-api";
import {DataTable} from "primereact/datatable";
import MarketDialog from "../../components/market/buy-dialog";
import {subscribe} from "../../utils/events";
import {Badge} from "primereact/badge";
import {ProgressBar} from "primereact/progressbar";
import CompanyMap from "../../components/company/company-map";
import LaunchRocket from "../../components/launch-rocket";
import ApplyContract from "../../components/contract/apply-contract";
import {connect} from "react-redux";
import DetailsMobile from "./details-mobile";
import ExtraFeatures from "./extra-features";
import {CompanyApi} from "../../api/company-api";
import {Toast} from "primereact/toast";
import {SplitButton} from "primereact/splitbutton";

type State = {
    company: any,
    contracts: any,
    showBuyRocketDialog: boolean,
    showBuyLaunchpadDialog: boolean,
    showApplyContractDialog: boolean,
    loading: boolean,
    launchRocket: boolean,
    rocketIdToLaunch: string | null,
    contractChoosen: any,
    rocketChoosen: any,
    canObtainGouvernementalGrant: boolean,
    toast: RefObject<any>
}

class CompanyDetails extends React.Component<any, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            company: null,
            contracts: null,
            showBuyRocketDialog: false,
            showBuyLaunchpadDialog: false,
            showApplyContractDialog: false,
            loading: true,
            launchRocket: false,
            rocketIdToLaunch: null,
            contractChoosen: null,
            rocketChoosen: null,
            canObtainGouvernementalGrant: false,
            toast: React.createRef()
        };

        subscribe('close-dialog', (data) => {
            switch(data.detail.type) {
                case 'close-market':
                    this.setState({showBuyRocketDialog: false, showBuyLaunchpadDialog: false, loading: true});
                    break;
                case 'close-launch-rocket':
                    this.setState({launchRocket: false});
                    break;
                case 'close-apply-contract':
                    this.setState({showApplyContractDialog: false});
                    break;
            }

            this.initData();
        })
    }

    getFreeLaunchpads = (rocketId: string) => {
        return this.state.company.launchPads.filter((i: any) => !i.isUsed).map((i: any) => {
            return { label: i.name, command: () => this.attachLaunchpad(rocketId, i._id) }
        })
    }

    attachLaunchpad = (rocketId: string, launchPadId: string) => {
        if(!rocketId || !launchPadId) {
            throw new Error("Missing parameters" + rocketId + " " + launchPadId);
        }
        RocketApi.attachRocketToLaunchpad(rocketId, launchPadId)
            .then(() => this.initData())
    }

    isMobile = () => {
        return window.innerWidth <= 768;
    }

    initData = () => {
        Promise.all([
            UserApi.getUserCompanies(),
            ContractsApi.getAvailableCOntracts(),
            RocketApi.getAvailableRockets()
        ]).then(([companiesResponse, contractsResponse]) => {
            const company = companiesResponse.data.find((company: any) => company._id === this.props.match.params.id);
            if (!this.props.companyStoreState) {
                this.props.setCompany(company);
            }
            this.setState({company, contracts: contractsResponse.data, canObtainGouvernementalGrant: this.canObtainGouvernementalGrant(company)});
        }).finally(() => {
            this.setState({loading: false});
            console.log(this.state)
        })
    }

    componentDidMount() {
        this.initData();
    }

    applyContract(contract: any) {
        this.setState({contractChoosen: contract, showApplyContractDialog: true});
    }

    canObtainGouvernementalGrant = (company: any) => {
        return company
            && company.lastGouvernementalGrant !== undefined
            && (company.lastGouvernementalGrant === null
            || new Date().getTime() - new Date(company.lastGouvernementalGrant).getTime() > 1000 * 60 * 60 * 2)
    }

    obtainGouvernementalGrant = () => {
        if(this.state.canObtainGouvernementalGrant){
            CompanyApi.obtainGouvernementalGrant(this.state.company._id).then((response) => {
                this.setState({company: response.data, canObtainGouvernementalGrant: this.canObtainGouvernementalGrant(response.data)})
                this.showToast("success", "Grant obtained!", "You obtained help from gouvernment! Next help available in 2h.")
            });
        }
    }

    showToast = (severity: 'success' | 'info' | 'danger' | 'warn' | 'secondary' | 'contrast', title: string, message: string) => {
        this.state.toast.current?.show({severity, summary: title, detail: message})
    }

    render() {
        return (
            <div>
                {this.state.loading && (
                    <div className="d-flex justify-center">
                        <i className="pi pi-spin pi-spinner" style={{fontSize: '3em'}}></i>
                    </div>
                )}
                {!this.state.loading && this.state.company && (

                    this.isMobile() ? <DetailsMobile/> : (
                        <div>

                            <Toast ref={this.state.toast} />

                            <div className="d-flex justify-between align-center">
                                <span className="d-flex align-center">
                                    <h1>{this.state.company.name}</h1>
                                    <span style={{
                                        fontSize: '1rem',
                                        margin: '0 10px'
                                    }}>
                                        {this.state.company.stockMarketValue.toLocaleString()}$
                                        <span className="ml-5">
                                            {this.state.company.oldStockMarketValue === this.state.company.stockMarketValue
                                                ? <i className="pi pi-equals" style={{color: 'orange'}}/>
                                                : (this.state.company.oldStockMarketValue < this.state.company.stockMarketValue
                                                        ? <i className="pi pi-sort-up-fill" style={{color: 'green'}}/>
                                                        : <i className="pi pi-sort-down-fill" style={{color: 'red'}}/>
                                                )
                                            }
                                        </span>
                                    </span>

                                    <Button icon="pi pi-building-columns" iconPos="right" className="p-button-success mx-5" disabled={!this.state.canObtainGouvernementalGrant} onClick={() => this.obtainGouvernementalGrant()}></Button>
                                </span>

                                <span className="d-flex align-center">
                                    <span style={{fontSize: '2rem', margin: '0 10px'}}>
                                        {this.state.company.money.toLocaleString()}<i className="pi pi-dollar"/>
                                    </span>
                                    <span style={{fontSize: '2rem'}}>{this.state.company.employees || 0} <i
                                        className="ml-2 mr-10 pi pi-users" style={{fontSize: '1.5rem'}}/></span>
                                    <Button className="p-button-info mr-10">Hire</Button>
                                </span>
                            </div>
                            <p>{this.state.company.description}</p>

                            {/*<img src="../../assets/t3-rocket.jpeg" alt="rocket" className="rocket-img" />*/}

                            <div className="d-flex gap-40" style={{width: '100%' }}>
                                <div className="flex-3" style={{width: 'inherit'}}>
                                    <CompanyMap company={this.state.company} height="70dvh"/>
                                </div>

                                <div className="d-flex flex-column align-center flex-1 gap-40">
                                    {this.state.company.rockets && (
                                        <Card style={{border: "1px solid #353535", width: "100%"}}>
                                            <div className="d-flex justify-between align-center">
                                                <h2 className="ma-0">Rockets</h2>

                                                {this.state.company.rockets && this.state.company.rockets.length > 0 && (
                                                    <Button icon="pi pi-plus"
                                                            className="p-button-rounded p-button-success"
                                                            aria-label="Bookmark"
                                                            onClick={() => this.setState({showBuyRocketDialog: true})}
                                                    />
                                                )}
                                            </div>
                                            {this.state.company.rockets && this.state.company.rockets.length === 0 ? (
                                                <div className="content d-flex flex-column align-center">
                                                    <h1>You doesn't have rockets ...</h1>
                                                    <Button className="p-button-info pa-5" outlined onClick={() => this.setState({showBuyRocketDialog: true})} label="Build rocket!" />
                                                </div>
                                            ) : (
                                                <>
                                                    {this.state.launchRocket && (
                                                        <LaunchRocket rocketId={this.state.rocketIdToLaunch!!}/>
                                                    )}
                                                    <DataTable value={this.state.company.rockets}
                                                               tableStyle={{minWidth: '50rem'}}>
                                                        <Column field="name" header="Name"></Column>
                                                        <Column field="orbital" header="Orbital" body={(item: any) => {
                                                            return item.orbital
                                                                ? <Badge value="Yes" severity="success"></Badge>
                                                                : <Badge value="No" severity="danger"></Badge>}} />
                                                        <Column field="maxPayloadUnits"
                                                                header="Max payloads units"></Column>
                                                        <Column field="maxPayloadWeight" header="Total payloads weight"
                                                                body={(i) => <p>{i.maxPayloadWeight}t</p>}></Column>
                                                        <Column body={(i) => (
                                                            i.launchPadId && i.launchPadId !== null
                                                                ? <Button severity="warning"
                                                                          disabled={!i.launchPadId}
                                                                          onClick={() => this.setState({launchRocket: true, rocketIdToLaunch: i._id})}>LAUNCH</Button>
                                                                : <SplitButton label="Set launchpad" icon="pi pi-map-marker" model={this.getFreeLaunchpads(i._id)}/>
                                                        )}/>
                                                    </DataTable>
                                                </>
                                            )}
                                        </Card>
                                    )}

                                    {this.state.company.launchPads && (
                                        <Card style={{border: "1px solid #353535", width: "100%"}}>
                                            <div className="d-flex justify-between align-center">
                                                <h2 className="ma-0">Launchpads</h2>
                                                {this.state.company.rockets && this.state.company.rockets.length > 0 && (
                                                    <Button icon="pi pi-plus"
                                                            className="p-button-rounded p-button-success"
                                                            aria-label="Bookmark"
                                                            onClick={() => this.setState({showBuyLaunchpadDialog: true})}
                                                    />
                                                )}
                                            </div>
                                            {this.state.company.launchPads && this.state.company.launchPads.length === 0 ? (
                                                <Card>
                                                    <div className="content d-flex flex-column align-center">
                                                        <h1>You doesn't have launchPads ...</h1>

                                                        <Button className="p-button-info pa-5" outlined onClick={() => this.setState({showBuyLaunchpadDialog: true})} label="Build launchPad!" />
                                                    </div>
                                                </Card>
                                            ) : (
                                                <DataTable value={this.state.company.launchPads}
                                                           tableStyle={{minWidth: '50rem'}}>
                                                    <Column field="name" header="Name"></Column>
                                                    <Column field="crew" header="Crew" body={(item: any) => {
                                                        return item.crew
                                                            ? <Badge value="Yes" severity="success"></Badge>
                                                            : <Badge value="No" severity="danger"></Badge>
                                                    }}></Column>
                                                    <Column field="isUsed" header="Used" body={(item: any) => {
                                                        return item.isUsed
                                                            ? <Badge value="Yes" severity="danger"></Badge>
                                                            : <Badge value="No" severity="success"></Badge>
                                                    }}></Column>
                                                </DataTable>
                                            )}
                                        </Card>
                                    )}
                                </div>
                            </div>

                            <div className="d-flex flex-column gap-40 mt-40">
                                {this.state.contracts && (
                                    <Card style={{border: "1px solid #353535"}}>
                                        <h2 className="ma-0">Available contracts</h2>
                                        <div className="contracts-list d-flex align-end">
                                            {this.state.contracts.map((object: any, i: number) => {

                                                const hasContract = this.state.company.contracts.length > 0 && this.state.company.contracts.find((c: any) => c.contractId === object._id);
                                                let timeLeft = 0;
                                                let timeTotal = 0;
                                                let pourcentage = 0;

                                                if(hasContract){
                                                    const companyContract = this.state.company.contracts.find((c: any) => c.contractId === object._id);

                                                    timeLeft = new Date(companyContract.endDate).getTime() - new Date().getTime();
                                                    timeTotal = new Date(companyContract.endDate).getTime() - new Date(companyContract.startDate).getTime();
                                                    pourcentage = Math.floor(Math.abs(((timeLeft / timeTotal) * 100) - 100));
                                                }

                                                const header = () => {
                                                    return (
                                                        <div>
                                                            {hasContract && (
                                                                <ProgressBar value={pourcentage}></ProgressBar>
                                                            )}
                                                            <div className="d-flex justify-between align-center mx-15">
                                                                <h2>{object.client}</h2>
                                                                <Button icon="pi pi-verified"
                                                                        label={hasContract ? "Contracted" : "Apply"}
                                                                        className="p-button-rounded p-button-info"
                                                                        aria-label="Bookmark"
                                                                        disabled={hasContract}
                                                                        onClick={() => this.applyContract(object)}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                return (
                                                    <div key={i} className="ma-10">
                                                        <Card style={{minWidth: '20rem', border: "1px solid #353535"}}
                                                              header={header}>
                                                            <p><span
                                                                className="bold-text">Duration</span> : {object.duration} hours
                                                            </p>
                                                            <p><span
                                                                className="bold-text">Payload</span> : {object.payloadName}
                                                            </p>
                                                            <p><span
                                                                className="bold-text">Quantity</span> : {object.payloadsQuantity}
                                                            </p>
                                                            <p><span
                                                                className="bold-text">Weight</span> : {object.payloadWeight}
                                                            </p>
                                                            <p><span
                                                                className="bold-text">Reward</span> : {object.price.toLocaleString()}$
                                                            </p>
                                                        </Card>
                                                    </div>
                                                );
                                            })}
                                        </div>

                                        {/*<DataTable value={this.state.contracts} stripedRows tableStyle={{minWidth: '50rem'}}>*/}
                                        {/*    <Column field="client" header="client"></Column>*/}
                                        {/*    <Column field="payloadName" header="Payload name"></Column>*/}
                                        {/*    <Column field="payloadWeight" header="Payload weight"></Column>*/}
                                        {/*    <Column field="price" header="Price" body={(item) => <p>{item.price.toLocaleString()}$</p>}></Column>*/}
                                        {/*    <Column body={() => <Button icon="pi pi-verified" className="p-button-rounded p-button-success" aria-label="Bookmark" />}></Column>*/}
                                        {/*</DataTable>*/}
                                    </Card>
                                )}

                                <ExtraFeatures company={this.state.company}/>
                            </div>

                        </div>
                    )
                )}

                {this.state.showBuyRocketDialog && (
                    <Dialog header="Market" visible={this.state.showBuyRocketDialog} style={{width: '50vw'}}
                            onHide={() => this.setState({showBuyRocketDialog: false})}>
                        <MarketDialog company={this.state.company} tab={'ROCKET'}/>
                    </Dialog>
                )}

                {this.state.showBuyLaunchpadDialog && (
                    <Dialog header="Market" visible={this.state.showBuyLaunchpadDialog} style={{width: '50vw'}}
                            onHide={() => this.setState({showBuyLaunchpadDialog: false})}>
                        <MarketDialog company={this.state.company} tab={'LAUNCHPAD'}/>
                    </Dialog>
                )}

                {this.state.showApplyContractDialog && this.state.contractChoosen && (
                    <Dialog header="Confirm contract" visible={this.state.showApplyContractDialog}
                            style={{width: '50vw'}}
                            baseZIndex={100}
                            onHide={() => this.setState({showApplyContractDialog: false})}>
                        <ApplyContract company={this.state.company} contract={this.state.contractChoosen}/>
                    </Dialog>
                )}
            </div>
        )
            ;
    }


    // const params = useParams();
    // const [state, setState] = useState({
    //   company: {}
    // })

    // function companyDetails () {
    //   UserApi.getUserCompanies().then((companies) => {
    //     const company = companies.find((company: any) => company._id === params.id);
    //     setState({ company });
    //   })
    // }

    // companyDetails();

    // return (
    //   <div>
    //     <h1>Company Details</h1>
    //     {state.company && (
    //       <p>Company ID: {state.company.name}</p>
    //     )}
    //   </div>
    // )
}

const mapDispatchToProps = (dispatch: any) => ({
    setCompany: (company: any) => (
        dispatch({type: "SET_COMPANY", payload: company})
    )
})

const mapStateToProps = (state: any) => {
    const {company} = state.company
    return {companyStoreState: company}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompanyDetails));
