import { Button } from "primereact";
import React from "react";

class Logout extends React.Component {

  logout(){
    localStorage.removeItem('token');
    window.location.href = '/home';
  }

  cancel(){
    window.location.href = '/home';
  }

  render() {
    return (
      <div>
        <h1>Logout</h1>
        <p>Do you really want to disconnect from your account ?</p>
        <Button className="p-button-outlined mr-10" label="Cancel" icon="pi pi-times" iconPos="left" onClick={this.cancel} />
        <Button className="mt-10" label="Confirm" icon="pi pi-check" iconPos="right" onClick={this.logout} />
      </div>
    );
  }
}

export default Logout;
