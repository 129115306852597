import React, {useState} from "react";
import {MapContainer, Marker, Polyline, Popup, TileLayer, useMapEvents} from "react-leaflet";
import L from "leaflet";

function LocationMarker({setPosition}: {setPosition?: Function}) {
    const [position, setStatePosition] = useState(null)
    useMapEvents({
        click(e: any) {
            if(setPosition) {
                setStatePosition(e.latlng)
                if(setPosition) setPosition(e.latlng)
            }
            // map.locate()
        },
        // locationfound(e: any) {
        //   setPosition(e.latlng)
        //   console.log(e.latlng)
        //   map.flyTo(e.latlng, map.getZoom())
        // },
    })

    // @ts-ignore
    const icon = new L.icon({
        iconUrl: '../../marker-icon.png',
        iconAnchor: [13, 41], // align icon to marker's bottom center
    });

    return position === null ? null : (
        <Marker position={position} icon={icon}>
        </Marker>
    )
}

type Props = { company: any, height?: string, width?: string, setCompanyPosition?: Function}
type State = { points: any }
export default class CompanyMap extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = { points: [] };
    }

    componentDidMount() {
        this.setState({points: [
            {type: "main", name: this.props.company.name, location: [this.props.company.latitude, this.props.company.longitude]},
            ...this.props.company.launchPads.map((launchpad: any) => {
                    return {type: "launchpad", name: launchpad.name, location: [launchpad.latitude, launchpad.longitude]}
                })
        ]});
    }

    getIconByType = (type: string) => {
        switch (type) {
            case 'main':
                return this.companyIcon();
            case 'launchpad':
                return this.launchPadIcon();
            default:
                throw new Error('Unknown type');
        }
    }

    // @ts-ignore
    companyIcon = () => new L.icon({
        iconUrl: '../../company-marker.png',
        iconSize: [25, 41],
        iconAnchor: [13, 41], // align icon to marker's bottom center
    });

    // @ts-ignore
    launchPadIcon = () => new L.icon({
        iconUrl: '../../marker-icon.png',
        iconSize: [25, 41],
        iconAnchor: [13, 41], // align icon to marker's bottom center
    });


    render(){
        return (
            <MapContainer center={[28.505, -80.6]} zoom={11} style={{height: this.props.height || '400px', minWidth: this.props.width || '300px', marginBottom: '5vh', borderRadius: '10px', zIndex: '1'}}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <LocationMarker setPosition={this.props.setCompanyPosition}/>
                <Polyline pathOptions={{color: 'blue', dashOffset: '3' }} positions={this.state.points.map((point: any) => point.location)}/>
                {this.state.points.map((point: any, index: number) => (
                    <Marker key={index} position={point.location} icon={this.getIconByType(point.type)}>
                        <Popup>
                            {point.name}
                        </Popup>
                    </Marker>
                ))}
            </MapContainer>
        )
    }
}