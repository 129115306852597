import Axios from "../api/config/axios.config";

export class CompanyApi {
  public static createCompany(body: any): Promise<any> {
    return Axios.post("/companies", body);
  }

  public static obtainGouvernementalGrant(companyId: string) {
    return Axios.patch(`/companies/${companyId}/gouvernemental-grant`);
  }
}