import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Dialog } from "primereact";
import React from "react";
import { UserApi } from "../../api/user-api";
import CreateCompany from "../../components/company/create-dialog";
import CompanyCard from "../../components/company/company-card";
import {faSackDollar, faLandmark} from '@fortawesome/free-solid-svg-icons'
import {connect} from "react-redux";

type State = { user: any, companies: any, showCreateCompanyDialog: boolean, loading: boolean }
class Home extends React.Component<any, State> {

  constructor(props: any) {
    super(props);
    this.state = { user: null, companies: null, showCreateCompanyDialog: false, loading: true };
    Promise.resolve(localStorage.getItem('token'))
    .then((token) => {
      if (token != null) {
        UserApi.getUserInfos().then((r1) => {
          this.setState({ user: r1.data });
          UserApi.getUserCompanies().then((r2: any) => {
            this.setState({ companies: r2.data });
            this.props.setCompanies(r2.data);
            this.props.setCompany(null);
          });
        })
      }
    })
    .finally(() => {
      this.setState({ loading: false });
    })
  }

  createCompany = () => {
    window.location.href = '/company/create';
  }

  render() {
    return (
      <div className="home">
        {this.state.loading && (
          <div className="d-flex justify-center">
            <i className="pi pi-spin pi-spinner" style={{ fontSize: '3em' }}></i>
          </div>
        )}
        {!this.state.loading && (
          <div className="content">
            {!this.state.user && (
              <div>
                <h1>Welcome to the home page</h1>
                <p>You are not logged in, <a href="/login">click here</a> to log yourself</p>
              </div>
            )}
            {this.state.user && (
              <div className="content">
                <div className="content-header d-flex justify-between align-center">
                  <h1>Welcome {this.state.user.username}</h1>
                  <div className="money-stats">
                    <span>300$ <FontAwesomeIcon icon={faSackDollar} /></span>
                    <span className="ml-10">10 903$ <FontAwesomeIcon icon={faLandmark} /></span>
                  </div>
                </div>
                <div className="d-flex justify-end align-center mb-10">
                  <Button label="Create a company" onClick={() => this.setState({showCreateCompanyDialog: true})} />
                </div>
                {this.state.companies && this.state.companies.length > 0 && (
                  <div className="companies-list">
                    {this.state.companies.map(function (object: any, i: number) {
                      return (
                        <div key={i} className="mt-10">
                          <CompanyCard company={object} />
                        </div>
                      );
                    })}
                  </div>
                )}

                {this.state.companies && this.state.companies.length === 0 && (
                  <Card style={{ border: "1px solid #353535"}}>
                    <div className="content d-flex flex-column align-center">
                      <h1>You are not part of any company ...</h1>
                      <span className="pa-20 ma-20" style={{ cursor: "pointer", border: "1px dashed white", borderRadius: "5px" }} onClick={() => this.setState({ showCreateCompanyDialog : true})}>
                        Create a company
                      </span>
                    </div>
                  </Card>
                )}
              </div>
            )}
          </div>
        )}
        {this.state.showCreateCompanyDialog && (
          <Dialog header="Create a company" visible={this.state.showCreateCompanyDialog} style={{ width: '80vw' }} onHide={() => this.setState({ showCreateCompanyDialog: false })}>
            <CreateCompany />
          </Dialog>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { company } = state
  return { company: company.value }
}


const mapDispatchToProps = (dispatch: any) => ({
  setCompanies: (companies: any) => (
      dispatch({ type: "SET_COMPANIES", payload: companies })
  ),
  setCompany: (company: any) => (
        dispatch({ type: "SET_COMPANY", payload: company })
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(Home);
