import React from 'react';
import { UserApi } from '../../api/user-api';
import QRCode from "react-qr-code";
import './security.css'
import { InfosApi } from '../../api/infos-api';
import { Button, InputText, Message, Messages, Steps } from 'primereact';

type State = {email: string, username: string, totp: string, totpUri: string, activeIndex: number}
class Signup extends React.Component<any, State> {

  msg: any;

  constructor(props: any) {
    super(props);

    this.state = {
      email: '',
      username: '',
      totp: '',
      totpUri: '',
      activeIndex: 0
    };

    this.msg = React.createRef();

    window.onload = () => {
      if (localStorage.getItem('token') != null) {
        InfosApi.getApiVersion().then((response) => {
          console.log(response.data);
        })
      }
    }
  }

  items = [
    { label: 'Register yourself' },
    { label: 'Save on your authenticator' },
    { label: 'Test connection' },
    { label: 'Done' }
  ];

  handleEmailChange = (event: any) => {
    this.setState({ email: event.target.value });
  }

  handleUsernameChange = (event: any) => {
    this.setState({ username: event.target.value });
  }

  handleTotpChange = (event: any) => {
    this.setState({ totp: event.target.value });
  }

  handleSubmit = (e: any) => {
  e.preventDefault();
  switch (this.state.activeIndex) {
    case 0:
      UserApi.registerUser({
        email: this.state.email,
        username: this.state.username
      }).then((response) => {
        this.setState({
          totpUri: response.data.totpUri,
          activeIndex: 1
        });
        return response
      }).catch((err) => {
        (this.msg as any).show([
          { severity: 'error', summary: 'Error : ', detail: err.response.data.message }
        ])
      })
      break;
    case 1:
      this.setState({
        activeIndex: 2
      });
      break;
    case 2:
      UserApi.login({
        email: this.state.email,
        token: this.state.totp
      }).then((response) => {
        this.setState({
          activeIndex: 3
        });
        localStorage.setItem('token', response.data.access_token);
      }).catch((error) => {
        (this.msg as any).show([
          { severity: 'error', detail: error.response.data.message }
        ])
      })
      break;
  }
}

  render(){
    return (
      <div className="signup d-flex flex-column align-center">
        <Steps model={this.items} activeIndex={this.state.activeIndex} />
        <h1>Sign up</h1>
        <Messages ref={(el: any) => this.msg = el}></Messages>
        <form className="d-flex flex-column align-center justify-center" onSubmit={this.handleSubmit}>
          <div className="d-flex">
            {this.state.activeIndex === 0 && (
              <div className='d-flex flex-column align-center gap-30'>
                <span className="p-float-label">
                  <InputText value={this.state.email} onChange={this.handleEmailChange} required />
                  <label htmlFor="in">Email</label>
                </span>

                <span className="p-float-label">
                  <InputText value={this.state.username} onChange={this.handleUsernameChange} required/>
                  <label htmlFor="in">Username</label>
                </span>
              </div>
            )}
          </div>
          {this.state.totpUri !== '' && (
            <div className='d-flex flex-column align-center'>

              {this.state.activeIndex === 1 && (
                <div className="d-flex flex-column align-center">
                  <div className='pa-7' style={{backgroundColor: 'white'}}>
                    <QRCode className='mt-10' value={this.state.totpUri} style={{ borderRadius: "5px" }} />
                  </div>
                  <p>Scan this QR code with your authenticator app, or <a href={this.state.totpUri}>click here</a> if you are on a smartphone.</p>
                  <Button className='mt-10' label="I saved it !" icon="pi pi-check" iconPos="right" />
                </div>
              )}

              {this.state.activeIndex === 2 && (
                <div className="step d-flex flex-column align-center">
                  <p>Try to authenticate yourself by typing the authenticate code linked to your Liftoff account on your authenticator app !</p>
                  <span className="p-float-label ma-15">
                    <InputText value={this.state.totp} onChange={this.handleTotpChange} />
                    <label htmlFor="in">Temporary code</label>
                  </span>
                </div>
              )}
            </div>
          )}

          {(this.state.activeIndex === 0 || this.state.activeIndex === 2) && (
            <Button className='mt-30' icon="pi pi-check" iconPos="right" label={this.state.totpUri !== '' ? 'Test your code' : 'Sign up !'} />
          )}

          {this.state.activeIndex === 3 && (
            <Message severity="success" text="Your account is now created and active !" />
          )}
        </form>
      </div>
    )
  }
}

export default Signup;
