import {Menubar} from 'primereact/menubar';
import React from 'react';
import {withRouter} from '../withRouter';
import {connect} from "react-redux";
import {Button} from "primereact";

class Navigation extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
    }

    isMobile = () => {
        return window.innerWidth <= 768;
    }

    render() {
        const items = [
            {
                label: 'Home',
                icon: 'pi pi-fw pi-home',
                command: () => this.props.navigate(localStorage.getItem('token') ? '/home' : '/login'),
                // items: [
                //   {
                //     label: 'New',
                //     icon: 'pi pi-fw pi-plus',
                //     items: [
                //       {
                //         label: 'Bookmark',
                //         icon: 'pi pi-fw pi-bookmark'
                //       },
                //       {
                //         label: 'Video',
                //         icon: 'pi pi-fw pi-video'
                //       },

                //     ]
                //   },
                //   {
                //     label: 'Delete',
                //     icon: 'pi pi-fw pi-trash'
                //   },
                //   {
                //     separator: true
                //   },
                //   {
                //     label: 'Export',
                //     icon: 'pi pi-fw pi-external-link'
                //   }
                // ]
            },
            {
                label: 'Quit',
                icon: 'pi pi-fw pi-power-off',
                command: () => this.props.navigate('/logout'),
            }
        ];

        const formatMoney = (money: number) => {
            if (money >= 100000 && money < 1000000) {
                return `${(money / 1000).toFixed(2)}k`
            } else if (money >= 1000000 && money < 1000000000) {
                return `${(money / 1000000).toFixed(2)}M`
            } else if (money >= 1000000000) {
                return `${(money / 1000000000).toFixed(2)}B`
            } else {
                return money.toFixed(2);
            }
        }

        const end = (this.props.company && this.isMobile() && (
            <span className="d-flex align-center justify-between" style={{width: '80vw'}}>
                                <span className="d-flex flex-column align-start">
                                    <span style={{fontWeight: 'bold'}}>{this.props.company.name}</span>
                                    <span style={{
                                        fontSize: '1rem'
                                    }}>
                                        {this.props.company.stockMarketValue.toLocaleString()}$
                                        <span className="ml-5">
                                            {this.props.company.oldStockMarketValue === this.props.company.stockMarketValue
                                                ? <i className="pi pi-equals" style={{color: 'orange'}}/>
                                                : (this.props.company.oldStockMarketValue < this.props.company.stockMarketValue
                                                        ? <i className="pi pi-sort-up-fill" style={{color: 'green'}}/>
                                                        : <i className="pi pi-sort-down-fill" style={{color: 'red'}}/>
                                                )
                                            }
                                        </span>
                                    </span>
                                </span>

                                <span className="d-flex align-center justify-end">
                                    <span style={{fontSize: '1rem', margin: '0 10px'}}>
                                    {formatMoney(this.props.company.money)}<i className="pi pi-dollar"/>
                                    </span>
                                    <span style={{fontSize: '1rem'}}>
                                        {this.props.company.employees || 0}
                                        <i className="ml-2 mr-10 pi pi-users"/>
                                    </span>
                                    <Button className="p-button-info mr-10" size="smallk">Hire</Button>
                                </span>
          </span>
        ))

        return (
            <div>
                <Menubar model={items} end={end}/>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    const {company} = state.company
    return {company: company}
}

export default connect(mapStateToProps, null)(withRouter(Navigation));