import {Button, Messages} from 'primereact';
import React, {useState} from "react";
import {TabPanel, TabView} from "primereact/tabview";
import {MarketApi} from "../../api/market-api";
import {publish} from "../../utils/events";
import {ProgressSpinner} from "primereact/progressspinner";
import {Steps} from "primereact/steps";
import {MapContainer, Marker, Popup, TileLayer, useMapEvents} from "react-leaflet";
import L from "leaflet";
import {InputText} from "primereact/inputtext";
import CompanyMap from "../company/company-map";

function LocationMarker({setItemLocation}: { setItemLocation: Function }) {
    const [position, setPosition] = useState(null)
    useMapEvents({
        click(e: any) {
            console.log(e)
            setItemLocation(e.latlng)
            setPosition(e.latlng)
            // map.locate()
        },
        // locationfound(e: any) {
        //   setPosition(e.latlng)
        //   console.log(e.latlng)
        //   map.flyTo(e.latlng, map.getZoom())
        // },
    })

    // @ts-ignore
    const icon = new L.icon({
        iconUrl: '../../marker-icon.png'
    });

    return position === null ? null : (
        <Marker position={position} icon={icon}>
            <Popup>
                <Button label="Buy" icon="pi pi-shopping-cart" className="p-button-success"/>
            </Popup>
        </Marker>
    )
}

type State = {
    itemsTypes: any[],
    items: any[],
    activeIndex: number,
    stepActiveIndex: number,
    loading: boolean,
    showLocation: boolean,
    steps: { label: string }[],
    itemChoosen: any,
    locationChoosen: any,
    nameChoosen: any
}
type Props = { company: any, tab?: string }

class MarketDialog extends React.Component<Props, State> {

    msg: any;

    constructor(props: any) {
        super(props);
        this.state = {
            itemsTypes: [],
            items: [],
            activeIndex: 0,
            stepActiveIndex: 0,
            loading: true,
            showLocation: false,
            itemChoosen: null,
            locationChoosen: null,
            nameChoosen: null,
            steps: [
                {label: 'Choose'},
                {label: 'Locate'},
                {label: 'Confirm'}
            ]
        };
        this.msg = React.createRef();
    }

    componentDidMount() {
        Promise.all([
            MarketApi.getItemsTypes(),
            MarketApi.getAllItems()
        ]).then(([itemsTypeResponse, itemsResponse]) => {
            this.setState({itemsTypes: itemsTypeResponse.data, items: itemsResponse.data});
            return itemsTypeResponse.data as any[]
        }).then((itemsTypes) => {
            this.setState({activeIndex: this.props.tab ? itemsTypes.findIndex((i: any) => i.code === this.props.tab) : 0});
            this.setStepActiveIndex();
        }).finally(() => this.setState({loading: false}));
    }

    handleTabChange = (e: any) => {
        console.log(this.state.itemsTypes[e.index]);
    }

    getItemsByType = (type: string) => {
        return this.state.items.filter((item: any) => item.type === type);
    }

    chooseItem = (item: any) => {
        this.setState({itemChoosen: item, nameChoosen: item.name})
    }

    setItemLocation = (data: any) => {
        console.log(data);
        this.setState({locationChoosen: data});
    }

    setStepActiveIndex = () => {
        if (!this.state.itemChoosen) {
            this.setState({stepActiveIndex: 0});
        } else {
            if (this.state.itemChoosen && this.state.itemChoosen.type !== "ROCKET" && !this.state.locationChoosen) this.setState({stepActiveIndex: 1});
            else this.setState({stepActiveIndex: 2});
        }
        console.log(this.state)
    }

    buyItem = () => {

        const body = {
            companyId: this.props.company._id,
            itemId: this.state.itemChoosen._id,
            name: this.state.nameChoosen,
            location: null as {lat: number, lng: number} | null
        }

        if(this.state.itemChoosen.type !== "ROCKET") {
            body.location = {
                lat: this.state.locationChoosen.lat,
                lng: this.state.locationChoosen.lng
            }
        }

        MarketApi.buyItem(body).then((response: any) => {
            publish('close-dialog', {type: 'close-market'});
        }).catch((err: any) => {
            (this.msg as any).show([
                {severity: 'error', detail: err.response.data.message}
            ]);
        });
    }

    isMobile = () => {
        return window.innerWidth <= 768;
    }

    render() {

        const content = () => {
            return (
                <>
                    <Steps model={this.state.steps} className="mb-30" activeIndex={this.state.stepActiveIndex}/>
                    <Messages ref={(el: any) => this.msg = el}/>

                    {this.state.stepActiveIndex === 0 && (
                        <TabView onBeforeTabChange={this.handleTabChange} activeIndex={this.state.activeIndex}>
                            {this.state.itemsTypes.map((itemType: any) => (
                                <TabPanel header={itemType.name} key={itemType.code}>
                                    <div className="d-flex justify-between align-center my-10">
                                        <span></span>
                                        <span>Name</span>
                                        <span>Price</span>
                                        <span></span>
                                    </div>
                                    {this.getItemsByType(itemType.code).map((item: any, index: number) => (
                                        <div key={index} className="d-flex justify-between align-center my-10">
                                            {item.img && <img src={"../../assets/" + item.img} alt="item"
                                                              style={{width: "50px", borderRadius: "5px"}}/>}
                                            <span>{item.name}</span>
                                            <span>{item.price.toLocaleString()}$</span>
                                            {this.state.itemChoosen && this.state.itemChoosen._id === item._id
                                                ? <Button label={this.isMobile() ? null : 'Choosen'} icon="pi pi-check" iconPos="right"
                                                          className="p-button-success"/>
                                                : <Button label={this.isMobile() ? null : 'Choose'} icon="pi pi-check" iconPos="right"
                                                          disabled={this.props.company.money < item.price}
                                                          onClick={() => this.chooseItem(item)}/>
                                            }
                                        </div>
                                    ))}
                                </TabPanel>
                            ))}
                        </TabView>
                    )}

                    {this.state.stepActiveIndex === 1 && (
                        // <MapContainer center={[28.505, -80.6]} zoom={11}
                        //               style={{height: '400px', minWidth: '300px', marginBottom: '5vh'}}>
                        //     <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                        //     <LocationMarker setItemLocation={this.setItemLocation}/>
                        // </MapContainer>
                        <CompanyMap company={this.props.company} height="400px" width="300px" setCompanyPosition={this.setItemLocation}/>
                    )}

                    {this.state.stepActiveIndex === 2 && (
                        <>
                            <div className="d-flex justify-center align-center flex-wrap my-10">
                                <span className="mr-10">Name</span>
                                <InputText value={this.state.nameChoosen}
                                           onChange={(e) => this.setState({nameChoosen: e.target.value})}/>
                            </div>
                            <div className="d-flex justify-center align-center pa-50">
                                <Button label="Buy" icon="pi pi-shopping-cart" size="large" className="p-button-success"
                                        onClick={() => this.buyItem()}/>
                            </div>
                        </>
                    )}
                    <div className="d-flex justify-end">
                        <Button label="Next" icon="pi pi-angle-double-right"
                                disabled={(this.state.stepActiveIndex === 0 && !this.state.itemChoosen) || (this.state.stepActiveIndex === 1 && !this.state.locationChoosen) || this.state.stepActiveIndex === 2}
                                className="p-button-success" onClick={() => this.setStepActiveIndex()}/>
                    </div>
                </>
            )
        }


        return (
            <div className="pa-10">

                {this.state.loading
                    ? <div className="d-flex justify-center"><ProgressSpinner style={{width: '50px', height: '50px'}}
                                                                              strokeWidth="8"
                                                                              fill="var(--surface-ground)"
                                                                              animationDuration=".5s"/></div>
                    : content()
                }
            </div>
        );
    }
}

export default MarketDialog;
