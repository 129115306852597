const initialState = {
    companies: null,
    company: null
}

const companyReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case "SET_COMPANIES":
            console.debug("set_companies")
            return {
                ...state,
                companies: action.payload
            }
        case "SET_COMPANY":
            console.debug("set_company")
            return {
                ...state,
                company: action.payload
            }
        default:
            return state;
    }
}

export default companyReducer;