import Axios from "./config/axios.config";

export type ApplyContractForm = {
    companyId: string;
    contractId: string;
    rocketId: string;
    launchPadId: string;
}

export class ContractsApi {
    static getAvailableCOntracts(){
        return Axios.get("/contracts")
    }

    static applyContract(data: ApplyContractForm){
        return Axios.post("/contracts/apply", data)
    }
}