import { Button, InputText, InputTextarea, Messages } from 'primereact';
import React, {useState} from "react";
import { CompanyApi } from "../../api/company-api";
import {MapContainer, Marker, Popup, TileLayer, useMapEvents} from "react-leaflet";
import L from "leaflet";

function LocationMarker({setCompanyPosition}: {setCompanyPosition: Function}) {
  const [position, setPosition] = useState(null)
  useMapEvents({
    click(e: any) {
      setCompanyPosition(e.latlng)
      setPosition(e.latlng)
      // map.locate()
    },
    // locationfound(e: any) {
    //   setPosition(e.latlng)
    //   console.log(e.latlng)
    //   map.flyTo(e.latlng, map.getZoom())
    // },
  })

  // @ts-ignore
  const icon = new L.icon({
    iconUrl: '../../marker-icon.png',
    iconAnchor: [13, 41], // align icon to marker's bottom center
  });

  return position === null ? null : (
      <Marker position={position} icon={icon}>
        <Popup>
          <Button label="Buy" icon="pi pi-shopping-cart" className="p-button-success" />
        </Popup>
      </Marker>
  )
}

type State = { name: string, description: string, latitude: number, longitude: number }
class CreateCompany extends React.Component<any, State> {

  msg: any;

  constructor(props: any) {
    super(props);
    this.state = {
      name: '',
      description: '',
      latitude: 0,
      longitude: 0
    };
    this.msg = React.createRef();
  }

  handleNameChange = (event: any) => {
    this.setState({ name: event.target.value });
  }

  handleDescriptionChange = (event: any) => {
    this.setState({ description: event.target.value });
  }

  handleSubmit = (e: any) => {
    e.preventDefault();
    CompanyApi.createCompany({
      name: this.state.name,
      description: this.state.description,
      logo: '',
      latitude: this.state.latitude,
      longitude: this.state.longitude
    }).then((response: any) => {
      window.location.href = '/home';
      return response;
    }).catch((err: any) => {
      (this.msg as any).show([
        { severity: 'error', detail: err.response.data.message }
      ]);
    });
  }

  setCompanyPosition = (data: {lat: number, lng: number}) => {
    console.log("received data : ", data)
    this.setState({latitude: data.lat, longitude: data.lng})
  }

  render() {
    return (
      <div className="pa-10">
        <Messages ref={(el: any) => this.msg = el} />
        <form onSubmit={this.handleSubmit}>
          <div className="p-float-label my-10">
            <InputText id="name" type="text" value={this.state.name} onChange={this.handleNameChange} required
                       style={{width: "100%"}}/>
            <label htmlFor="name">Name</label>
          </div>
          <div className="p-float-label mt-30">
            <InputTextarea id="description" value={this.state.description} onChange={this.handleDescriptionChange}
                           required style={{width: "100%"}}/>
            <label htmlFor="description">Description</label>
          </div>
          <div className="p-float-label mt-30">
            <MapContainer center={[28.505, -80.6]} zoom={11} style={{height: '400px', minWidth: '300px'}}>
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <LocationMarker setCompanyPosition={this.setCompanyPosition} />
            </MapContainer>
            <label htmlFor="description">Position</label>
          </div>
          <Button label="Create" icon="pi pi-check" iconPos="right" className="mt-20"/>
        </form>
      </div>
    );
  }
}

export default CreateCompany;