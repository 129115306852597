import Axios from "../api/config/axios.config";

export class RocketApi {

    public static getAvailableRockets(): Promise<any> {
        return Axios.get("/rockets");
    }

    public static launchRocket(rocketId: string) {
        return Axios.post(`/rockets/${rocketId}/launch`)
    }

    public static attachRocketToLaunchpad(rocketId: string, launchpadId: string) {
        return Axios.post(`/rockets/${rocketId}/attach/${launchpadId}`)
    }
}