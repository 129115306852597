import React from "react";
import {Dropdown} from "primereact/dropdown";
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {ContractsApi} from "../../api/contracts-api";
import {publish} from "../../utils/events";

type Props = { company: any, contract: any }
type State = { selectedRocket: any, availableRockets: any[], selectedLaunchpad: any, availableLaunchpads: any[] };

class ApplyContract extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        console.log(this.props.company.rockets, this.props.contract)
        this.state = {
            selectedRocket: null,
            availableRockets: this.props.company.rockets
                .filter((rocket: any) => rocket.maxPayloadWeight >= this.props.contract.payloadWeight),
            selectedLaunchpad: null,
            availableLaunchpads: this.props.company.launchPads
        }

        console.log(this.props.company.rockets
            .filter((rocket: any) => rocket.maxPayloadWeight >= this.props.contract.payloadWeight))
    }

    setSelectedRocket = (rocket: any) => {
        this.setState({selectedRocket: rocket});
    }

    setSelectedLaunchpad = (launchpad: any) => {
        this.setState({selectedLaunchpad: launchpad});
    }

    applyContract = () => {
        if (!this.state.selectedRocket || !this.state.selectedLaunchpad) {
            return;
        }

        console.log(this.props, this.state)

        ContractsApi.applyContract({
            companyId: this.props.company._id,
            contractId: this.props.contract._id,
            rocketId: this.state.selectedRocket._id,
            launchPadId: this.state.selectedLaunchpad._id
        }).then(() => {
            publish('close-dialog', {type:'close-apply-contract'});
        });
    }

    render() {
        return (
            <div className="d-flex justify-between" style={{alignItems: 'inherit', gap: '30px'}}>
                <div style={{flex: '3'}} className="d-flex flex-column justify-between">
                    <div>
                        <h3>Rocket</h3>
                        <Dropdown value={this.state.selectedRocket} onChange={(e) => this.setSelectedRocket(e.value)}
                                  options={this.state.availableRockets} optionLabel="name"
                                  placeholder="Select a Rocket" className="w-full md:w-14rem"/>

                        <h3>Launchpad</h3>
                        <Dropdown value={this.state.selectedLaunchpad}
                                  onChange={(e) => this.setSelectedLaunchpad(e.value)}
                                  options={this.state.availableLaunchpads} optionLabel="name"
                                  placeholder="Select a Launchpad" className="w-full md:w-14rem"/>
                    </div>

                    <Button className="p-button p-button-success mt-20" style={{width: 'fit-content'}}
                            icon="pi pi-check" iconPos="right" onClick={() => this.applyContract()}
                    disabled={!this.state.selectedRocket || !this.state.selectedLaunchpad}>Apply</Button>
                </div>
                <Card style={{border: "1px solid #353535", flex: '3'}} title="Contract details">
                    <p><strong>Client:</strong> {this.props.contract.client}</p>
                    <p><strong>Payload:</strong> {this.props.contract.payloadName}</p>
                    <p className="ml-20"><strong>Quantity:</strong> {this.props.contract.payloadsQuantity}</p>
                    <p className="ml-20"><strong>Weight:</strong> {this.props.contract.payloadWeight} t</p>
                    <p><strong>Reward:</strong> {this.props.contract.price.toLocaleString()} $</p>
                </Card>
            </div>
        )
    }
}

export default ApplyContract;
